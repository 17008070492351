<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init" class="cur-a">直播管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationControlCurr operationControlWidth"
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="liveProjectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="班级状态" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:6rem">班级状态:</span>
                <el-select
                  size="small"
                  v-model="classStatus"
                  placeholder="请选择班级状态"
                  clearable
                >
                  <el-option
                    v-for="item in ClassstatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div title="班级编码" class="searchboxItem searchboxItems">
                <span class="itemLabel" style="min-width:6rem">班级编码:</span>
                <el-input
                  v-model="projectCode"
                  type="text"
                  size="small"
                  placeholder="请输入班级编码"
                  clearable
                />
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="
                      () => {
                        getData();
                      }
                    "
                    v-if="flag == false"
                    >查询</el-button
                  >
                </transition>
              </div>
              <div style="margin-left: 10px">
                <el-button @click="search()" size="small" type="text">
                  高级搜索
                  <i :class="flag ? 'el-icon-top' : 'el-icon-bottom'"></i>
                </el-button>
              </div>
            </div>
            <div class="btnBox">
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addTestPaper"
                >新建班级</el-button
              >
            </div>
          </div>
          <el-collapse-transition>
            <div>
              <div
                class="searchbox"
                style="margin-top: 10px; width: 100%"
                v-show="flag == true"
              >
                <div class="searchbox">
                  <div title="负责人" class="searchboxItem">
                    <span class="itemLabel">负责人:</span>
                    <el-input
                      v-model="ruleForm.projectUser"
                      type="text"
                      size="small"
                      placeholder="请输入负责人"
                      clearable
                    />
                  </div>
                  <div title="负责人电话" class="searchboxItem">
                    <span class="itemLabel projectUser" style="min-width:6rem">负责人电话:</span>
                    <el-input
                      v-model="ruleForm.projectUserPhone"
                      type="text"
                      size="small"
                      placeholder="请输入负责人电话"
                      clearable
                    />
                  </div>
                  <div title="行政区划" class="searchboxItem">
                    <span class="itemLabel" style="min-width:6rem">行政区划:</span>
                    <el-cascader
                      clearable
                      filterable
                      v-model="ruleForm.areaId"
                      :options="areatreeList"
                      :props="propsarea"
                      size="small"
                    ></el-cascader>
                  </div>
                  <div title="所属期次" class="searchboxItem searchboxItems">
                    <span class="itemLabel">所属期次:</span>
                    <el-select
                      v-model="periodId"
                      placeholder="请选择所属期次"
                      size="small"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in periodList"
                        :key="item.periodId"
                        :label="item.periodName"
                        :value="item.periodId"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div
              class="searchbox"
              style="margin-top: 10px; width: 100%"
              v-show="flag == true"
            >
              <div class="searchbox">
                <div title="培训类型" class="searchboxItem">
                  <span class="itemLabel">培训类型:</span>
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    @clearParams="clearParams"
                    typeStu
                  />
                </div>
                <div title="开班时间" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:6rem">开班时间:</span>
                  <el-date-picker
                    v-model="ruleForm.opentime"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  />
                </div>
                <div title="结束时间" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:6rem">结束时间:</span>
                  <el-date-picker
                    clearable
                    size="small"
                    v-model="ruleForm.endTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </div>
                <div>
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="
                      () => {
                        getData();
                      }
                    "
                    >查询</el-button
                  >
                </div>
                <div style="margin-left: 10px">
                  <el-button type="text" round @click="resetForm"
                    >清空搜索条件</el-button
                  >
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div style="margin: 15px 0 5px">
          <span>班级总数：{{ projectNum }}</span>
          <span style="margin: 0 30px">班级总人次：{{ projectPeopleNum }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="liveProjectName"
                min-width="250px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="200"
              />
              <el-table-column
                label="所属期次"
                align="left"
                show-overflow-tooltip
                prop="periodName"
                min-width="250px"
              />
              <el-table-column
                label="行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaName"
                width="150px"
              />
              <el-table-column
                label="培训类型"
                align="center"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="120px"
              />
              <el-table-column
                label="岗位类型"
                align="center"
                show-overflow-tooltip
                prop="postName"
              >
                <template slot-scope="scope">
                  {{ scope.row.postName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="center"
                show-overflow-tooltip
                prop="industryNamePath"
              >
                <template slot-scope="scope">
                  {{ scope.row.industryNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="职业/工种"
                align="center"
                show-overflow-tooltip
                prop="occupationNamePath"
                width="200"
              >
                <template slot-scope="scope">
                  {{ scope.row.occupationNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训等级"
                align="center"
                show-overflow-tooltip
                prop="trainLevelName"
              >
                <template slot-scope="scope">
                  {{ scope.row.trainLevelName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="负责人"
                align="center"
                show-overflow-tooltip
                prop="projectUser"
              />
              <el-table-column
                label="负责人电话"
                align="center"
                show-overflow-tooltip
                prop="projectUserPhone"
                width="120px"
              />
              <el-table-column
                label="开始时间"
                align="center"
                show-overflow-tooltip
                prop="startDate"
                width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="班级状态"
                align="left"
                show-overflow-tooltip
                prop="projectState"
                width="100px"
              ><template slot-scope="scope">          
                  <template v-if="scope.row.projectState == 40">             
                    <el-tooltip effect="dark" :content="scope.row.auditReason" placement="top-start">                  
                      <span :class="scope.row.projectState == 40?'tColor1':''">{{                       
                        $setDictionary("PROJECTSTATE", scope.row.projectState)
                      }}<i class="el-icon-question" style="color:red;font-size: 14px;"></i> </span>       
                    </el-tooltip>                     
                  </template> 
                  <template v-else>
                    <span>{{
                      $setDictionary("PROJECTSTATE", scope.row.projectState)
                    }}</span>      
                  </template>                
                </template>           
              </el-table-column>
              <el-table-column
                label="班级人数"
                align="center"
                show-overflow-tooltip
                prop="actualUserNum"
                width="140px"
              ><template slot-scope="scope">      
                  {{ Number(scope.row.actualUserNum) > 0? scope.row.actualUserNum: '--' }}   
                </template>       
              </el-table-column>

              <el-table-column
                label="已完成人数"
                align="center"
                show-overflow-tooltip
                prop="finishedPeople"
                width="140px"
              ><template slot-scope="scope">      
                  {{ Number(scope.row.finishedPeople) > 0? scope.row.finishedPeople: '--' }}   
                </template>   
              </el-table-column>  
              <el-table-column
                label="培训进度"
                align="center"
                show-overflow-tooltip
                prop="trainSchedule"
                width="140px"
              />

              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="seeDetails(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpclassList(scope.row)"
                      >直播列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpStudentList(scope.row)"
                      >学员列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="
                        keeprecord(
                          scope.row.liveProjectId,
                          scope.row.courseCount,
                          scope.row
                        )
                      "
                      :disabled="
                        scope.row.projectState == '20' ||
                        scope.row.projectState == '30' || scope.row.projectState == '50' ||scope.row.projectState == '60'
                      "
                      >开班申请</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleteproject(scope.row.liveProjectId)"
                      :disabled="
                        scope.row.auditState == '20' ||
                        scope.row.auditState == '30' ||
                        scope.row.projectSource == '20'
                      "
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "liveClassManagement",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], //行政区划数据
      ClassstatusList: [], //班级状态数据
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      liveProjectName: "", //班级名称
      classStatus: "", //班级状态
      projectCode: "", // 班级编码
      periodId: "", //所属期次
      ruleForm: {
        areaId: "", //行政区划
        projectUser: "", //负责人
        projectUserPhone: "", //负责人电话
        opentime: "", //开班时间
        endTime: "", //结束时间
        Trainingtype: "", //培训类型
      },
      params: {},
      projectNum: "", //班级总数：
      projectPeopleNum: "", //班级总人次
      flag: false, //查询按钮
      Spare: "",
      periodList: [],
      periodIdSearch: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    if (this.$route.query.periodId) {
      this.periodId = this.$route.query.periodId;
    }
    this.getData(-1)
    this.getTableHeight();
    this.getareatree();
    this.getclassstatusList();
    this.getSelectPeriod();
  },
  methods: {
    //获取班级总数和班级总人次
    getTotal(params) {
      this.$post("/liveProject/live-project/count", params, 3000, true, 3)
        .then((ret) => {
          this.projectNum = ret.data.projectCount;
          this.projectPeopleNum = ret.data.peopleCount;
        })
        .catch((err) => {
          return;
        });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    //所属期次
    getSelectPeriod() {
      this.$post("/biz/live/period/getPeriodList", {}, 3000, true, 3)
        .then((ret) => {
          this.periodList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.liveProjectName) {
        params.liveProjectName = this.liveProjectName.trim();
      }
      if (this.periodId) {
        params.periodId = this.periodId;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectUser) {
        params.projectUser = this.ruleForm.projectUser;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.classStatus) {
        params.projectState = this.classStatus;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.ruleForm.opentime) {
        params.startStartDate = this.ruleForm.opentime[0];
        params.startEndDate = this.ruleForm.opentime[1];
      }
      if (this.ruleForm.endTime) {
        params.endStartDate = this.ruleForm.endTime[0];
        params.endEndDate = this.ruleForm.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch(
        {
          url: "/liveProject/live-project/pageList",
          params,
          pageNum,
        },
        true,
        3
      );
      this.getTotal(params);
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list;
    },
    /* 开班申请 */
    keeprecord(liveProjectId, courseCount, row) {
      if (Number(courseCount) < 1) {
        this.$confirm("你的班级还未配置直播课程，请先为班级配课。", "提示", {
          confirmButtonText: "班级配课",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.jumpclassList(row);
          })
          .catch(() => {
            return;
          });
      } else {
        this.$confirm(
          "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改。",
          "开班申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$post(
              "/liveProject/live-project/affirm",
              { liveProjectId },
              3000,
              true,
              3
            )
              .then((ret) => {
                if (ret.status == 0) {
                  this.getData();
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch((err) => {});
      }
    },
    //删除
    deleteproject(liveProjectId) {
      this.doDel(
        {
          url: "/liveProject/live-project/delete",
          msg: "你确定要删除该班级吗？",
          ps: {
            type: "post",
            data: { liveProjectId },
          },
        },
        true,
        3
      );
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("liveProjectId", row.liveProjectId);
       sessionStorage.setItem("liveProjectName", row.liveProjectName);
      sessionStorage.setItem("projectState", row.projectState);
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassDetailsList",
        query: {
          active: "first",
        },
      });
    },
    //直播课程列表
    jumpclassList(row) {
      sessionStorage.setItem("liveProjectId", row.liveProjectId);
      sessionStorage.setItem("liveProjectName", row.liveProjectName);
      sessionStorage.setItem("projectState", row.projectState);
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassDetailsList",
        query: {
          active: "second",
        },
      });
    },
    //跳转学员列表
    jumpStudentList(row) {
      sessionStorage.setItem("liveProjectId", row.liveProjectId);
       sessionStorage.setItem("liveProjectName", row.liveProjectName);
      sessionStorage.setItem("projectState", row.projectState);
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassDetailsList",
        query: {
          active: "three",
        },
      });
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/liveBroadcastManagement/addLiveClass",
        query: {
          projectId: null,
          stu: "add",
        },
      });
    },
    // 高级搜索弹窗
    search() {
      this.flag = !this.flag;
      this.params = {};
      this.getTableHeight();
    },
    //清空搜索条件
    resetForm() {
      this.params = {};
      this.ruleForm = {};
      this.liveProjectName = "";
      this.projectCode = "";
      this.classStatus = "";
      this.periodId = "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.flag == false ? 80 + 0.675 * 16 + 6 : 170 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.liveProjectName = "";
            this.Classstatus = "";
            this.periodId = "";
            this.ruleForm = {
              areaId: "",
              projectUser: "",
              projectUserPhone: "",
              opentime: "",
              endTime: "",
              Trainingtype: "",
            };
            this.params = {};
            this.getData();
          } else {
            this.getData();
          }
        } else {
          this.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
.tColor1:hover {
  color: #409EFF;
}
</style>